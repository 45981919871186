<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				faq_content:[]
            }
		},
		async mounted() {
			await this.ready();
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 300)
            this.$set(this.$root, 'page', this);
            this.getApi()
            if (typeof SEMICOLON != "undefined") {
                setTimeout(()=>{
	            	SEMICOLON.widget.init()
	            },500)
            }
		},
        methods: {
        	getApi() {
				Gen.apirest("/faq-content",{},(err,resp)=>{
					// this.$root.topProgress.done()
					if(err) console.log(err)
					this.faq_content = resp.data
				})
			},
        },
	};
</script>
<template>
	<section id="content" style="background-color: #000; overflow: visible">
	    <div class="content-wrap pt-0 pb-0">
	        <div id="section-about" class="section page-section bg_white nobottommargin notopmargin clearfix">
	            <div class="container clearfix">
	                <div class="row  justify-content-center ">
	                    <div class="col-md-8">
	                        <div class="wrap_join">
	                            <h2 class="text-left">{{ web.mn_faq }}</h2>
	                            <p class="text-left">{{ web.faq_order_type }}</p>
	                            <div class="row">
	                                <div class="col-md-12">	                                    
	                                    <div class="accordion clearfix">
	                                    	<div v-for="(v,k) in faq_content" :key="k">
		                                        <div class="acctitle"><i class="acc-closed icon-ok-circle"></i><i class="acc-open icon-remove-circle"></i> {{ v.question }}</div><div class="acc_content clearfix">{{ v.answer }}</div>
	                                        </div>
	                                    </div>
	                                </div>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </div>
	        <div class="clear"></div>
	    </div>
	</section>
</template>